<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="customers"
      :items-per-page="10"
      :search="search"
      sort-by="first_name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiAccountTie }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(customer, index) in items" :key="index">
            <td>
              <v-icon class="mr-1">{{ icons.mdiAccountTie }}</v-icon
              >{{ customer.first_name }}
            </td>
            <td>{{ customer.last_name }}</td>
            <td>{{ customer.company_name }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ formatDate(customer.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div v-show="currentUser.role_id == 1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        @click.prevent="restoredCustomer(customer.customer_id)"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>{{ icons.mdiRestore }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Restore</span>
                  </v-tooltip>
                </div>
                <div>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="currentUser.role_id == 1"
                          @click.prevent="deleteCustomer(customer.customer_id)"
                          v-bind="attrs"
                          v-on="on"
                          color="error"
                          fab
                          x-small
                          dark
                          class="mx-1"
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Permanently</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:no-data>
        <v-btn color="accent" @click="getCustomers"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
  
  <script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiRestore,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {},

  created: function () {
    this.getCustomers()
    this.getAuthorizations()
  },
  data() {
    return {
      headers: [
        {
          text: 'First Name',
          value: 'first_name',
        },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Company Name', value: 'company_name' },
        { text: 'Email', value: 'email' },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      customers: [],
      permission: {},
      search: '',
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiRestore,
      },
    }
  },

  methods: {
    getCustomers: function () {
      this.$store
        .dispatch('customer/deletedCustomerLogs')
        .then(response => {
          this.customers = this.fetchCustomers
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 7,
          submenu_id: 3,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteCustomer: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`customer/deletedCustomerLogs`, { customer_id: id })
              .then(response => {
                this.$router.push({ name: 'logCustomer' })
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    restoredCustomer: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure you want to restore this?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Restored!',
              text: 'The record has been restored.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })

            this.$store
              .dispatch(`customer/restoredeletedCustomerLogs`, { customer_id: id, data: this.customer })
              .then(response => {
                this.getCustomers()
              })

              .catch(err => {
                console.log(err)
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchCustomers: 'customer/fetchCustomers',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

